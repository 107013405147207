<template>
  <div class="guide-form-comp">
    <el-form
      :model="ruleForm"
      :rules="rules"
      label-position="top"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-row>
        <el-form-item label="业务类型" prop="businessType">
          <el-select style="width: 100%" v-model="ruleForm.businessType">
            <el-option
              v-for="(item, index) in businessTypeList"
              :value="item.value"
              :label="item.label"
              :key="index"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-row>
      <el-row v-loading="uploadLoading">
        <el-form-item label="服务模板" prop="fileList">
          <div class="temolate-list-container">
            <div class="title-line">
              <span>文件名称</span>
              <span>文件大小</span>
              <span>文件格式</span>
              <span>操作</span>
            </div>
            <div v-if="fileList.length === 0" class="no-data-line">
              <span>暂无数据</span>
            </div>
            <div v-else>
              <div
                v-for="(item, index) in fileList"
                :key="index"
                class="detail-line"
              >
                <span>{{ item.fileName }}</span>
                <span>{{ size2Str(item.fileSize) }}</span>
                <span>{{ item.fileFormat }}</span>
                <span>
                  <i
                    class="el-icon-delete"
                    @click="fileSingleDelete(index)"
                  ></i>
                </span>
              </div>
            </div>
          </div>
          <el-upload
            class="avatar-uploader"
            :disabled="fileList.length === 5"
            :on-success="uploadSuccess"
            :on-error="uploadFail"
            :before-upload="beforeUpload"
            :show-file-list="false"
            :with-credentials="true"
            :accept="'.pdf, .xls, .xlsx, .ppt, .pptx, .doc, .docx'"
            :action="uploadPath"
          >
            <div
              class="upload-handler-line"
              :style="{
                cursor: fileList.length === 5 ? 'not-allowed' : 'pointer',
              }"
            >
              <el-tooltip
                :disabled="fileList.length < 5"
                class="item"
                effect="dark"
                content="最多支持上传5个服务模板"
                placement="top"
              >
                <i class="el-icon-upload"></i>
                <span>点击上传附件</span>
              </el-tooltip>
            </div>
          </el-upload>
        </el-form-item>
      </el-row>
      <el-row :gutter="48">
        <el-col :span="12">
          <el-form-item label="立刻启用" prop="status">
            <el-radio-group v-model="ruleForm.status">
              <el-radio border label="0">启用</el-radio>
              <el-radio border label="1">禁用</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-form-item align="right">
          <el-button type="primary" @click="submitForm('ruleForm')"
            >确定</el-button
          >
          <el-button @click="resetForm">取消</el-button>
        </el-form-item>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { addServiceGuide } from "@/api/ruge/gsPark/customerService/serviceGuide";
import { envInfo } from "@/constants/envInfo";
import { size2Str } from "@/utils/utils.js";
export default {
  props: {
    businessTypeList: {
      type: Array,
      required: true,
    },
    id: {
      type: Number,
    },
  },

  data() {
    var validatorFileList = (rule, value, callback) => {
      if (this.fileList.length === 0) {
        callback(new Error("请上传服务模板！"));
      } else {
        callback();
      }
    };
    return {
      uploadPath:
        envInfo.bgApp.archivePath +
        "/param/archive/upload?ulType=DefaultUpload&scopeType=ALL",
      ruleForm: {
        businessType: "",
        status: "0",
        fileList: [],
      },
      rules: {
        businessType: [
          { required: true, message: "请选择业务类型", trigger: "blur" },
        ],
        status: [
          { required: true, message: "请选择是否立刻启用", trigger: "blur" },
        ],
        fileList: [
          { required: true, validator: validatorFileList, trigger: "blur" },
        ],
      },
      uploadLoading: false,
      fileList: [],
    };
  },
  methods: {
    size2Str,
    fileSingleDelete(index) {
      this.fileList.splice(index, 1);
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitHandler();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    submitHandler() {
      let params = [];
      this.fileList.forEach((item) => {
        params.push({
          fileName: item.fileName,
          fileId: item.fileId,
          fileFormat: item.fileFormat,
          fileSize: item.fileSize,
          businessType: this.ruleForm.businessType,
          status: Number(this.ruleForm.status),
        });
      });
      addServiceGuide(params).then((res) => {
        if (res.code === 200) {
          this.$message.success("新增成功！");
          this.$emit("close", true);
        } else {
          this.$message.warning("新增失败！");
        }
      });
    },
    resetForm() {
      this.$emit("close", false);
    },
    uploadFail() {
      this.$message({ type: "error", message: this.$t("message.uploadFial") });
      this.uploadLoading = false;
    },
    beforeUpload(file) {
      this.uploadLoading = true;
      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 5MB!");
        this.uploadLoading = false;
        return false;
      }
    },
    uploadSuccess(files) {
      this.fileList.push(files[0]);
      // 处理item.displayName
      this.dealFileItems();
      this.$message({
        type: "success",
        message: this.$t("message.uploadSuccess"),
      });
      this.uploadLoading = false;
    },
    dealFileItems() {
      this.fileList.forEach((item) => {
        item.fileName = item.displayName.replace(/(.*\/)*([^.]+).*/gi, "$2");
        item.fileFormat = item.displayName.replace(/.+\./, "");
      });
    },
  },
};
</script>

<style scoped lang="less">
.guide-form-comp {
  .temolate-list-container {
    .title-line {
      border-bottom: 1px solid #ebeef5;
      display: flex;
      & > span {
        flex: 1;
        text-align: center;
        color: #909399;
        font-weight: 500;
      }
    }
    .no-data-line {
      text-align: center;
      border-bottom: 1px solid #e8e8e8;
    }
    .detail-line {
      border-bottom: 1px solid #ebeef5;
      display: flex;
      & > span {
        flex: 1;
        text-align: center;
      }
      i {
        cursor: pointer;
      }
    }
  }
  .avatar-uploader {
    ::v-deep .el-upload {
      width: 100%;
    }
    .upload-handler-line {
      border-bottom: 1px solid #ebeef5;
      text-align: center;
      i {
        margin-right: 5px;
      }
    }
  }
}
</style>